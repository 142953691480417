
.mobileLayout{
    height:100%;
    display:flex;
    flex-direction: column;
}

.headerStyle{
    position:fixed;
    width:100%;
    padding:0;
    margin:0;
    top:0px;
    left:0px;
    height:56px; 
    display:block;  
    z-index: 9999;     
    }

.content{
    margin:0px;
    margin-top:56px;
    padding:0px; 
    padding-bottom:0px; 
    flex:1;
    overflow:auto;
    z-index: 1;    
}

 .mobileMenu{
    position:fixed;
    width:100%;
    left:0px;
    height:64px;
    bottom:0;
 }

 .line{
      height:1px;
      background-color:lightgray;
      border-width:0px;
      margin:0px;
      padding:0px;
    
    } 
    .root {
        width: '100%';
    }

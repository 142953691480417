@import '~antd/dist/antd.css';

.gm-style .gm-style-cc a,
.gm-style .gm-style-cc button,
.gm-style .gm-style-cc span,
.gm-style .gm-style-mtc div {
  font-size: 12px;

}

/* 
.gm-control-active{
height:48px !important;
width:48px!important;
}

.gm-bundled-control-on-bottom {
  height: 96px !important;
  width: 48px !important;
  bottom: 24px!important;
  right:16px!important;
}

.gm-bundled-control-on-bottom.div.gmnoprint.div {
  height: 96px !important;
  width: 48px !important;
}

.gm-bundled-control-on-bottom.div.gmnoprint.div.div {
  height: 96px !important;
  width: 48px !important;
}

div.gmnoprint{
  height: 96px !important;
  width: 48px !important;
}

div.gmnoprint .div {
  height: 96px !important;
  width: 48px !important;
} */

a {
  color: #097090;
}

a:hover {
  color: #0b5980;
}

.ant-modal-header {
  background-color: #097090;
  color: white;
}

.ant-btn {
  font-weight: 500;
}

.ant-modal-close-icon {
  color: white;
  height: 1.5em;
}



element.style {}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

element.style {}



.ant-input-search,
.ant-input:hover,
.ant-input:focus {
  border-color: black !important;
  color: black !important;
  opacity: 1;
}

.ant-input::placeholder {
  color: #0b5980;
  font-weight: 600;
}


.ant-input-group {
  color: black !important;
}

.ant-btn-primary:hover {
  background-color: #097090;
  border-color: #097090;
  color: white;
  /* line-height: 42px; */
}

.ant-btn {
  font-weight: 500;

}

/* .ant-btn-primary {
  color: #fff;
  background: #097090;
  border-color: #097090;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
} */

.ant-btn-default {
  background-color: white;
  border-color: black;
  border-width: 2px;
  color: black;
}

.ant-btn-default:hover {
  background-color: white;
  border-color: black;
  border-width: 2px;
  color: black;
}

.ant-radio-button {
  background: #097090;
  border-color: #097090;
  padding-top: 8px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  border-color: #097090;
  color: #097090;
  outline-color: #097090;
  text-shadow: #097090;
  font-size: 18px;
  line-height:32px;
  height: 40px;
  border-right-color: #097090;
  margin: 0px;

}

.ant-tabs-tab-active {
  border-right-color: #0b5980;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  border-color: #0b5980;
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  height: 40px;

}
.ant-checkbox-input{
  font-size: 24px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #0b5980;
  background-color: #0b5980;
}

.ant-checkbox-inner {
  border-color: black;
  color: black;
  height:24px;
  width:24px;
}

.ant-radio-group-large .ant-radio-button-wrapper {
  line-height: 42px;
  font-weight: 400;
}

.img {
  min-height: 48px;
}


.ant-input-search-large .ant-input-search-button {
  height: 48px;
}

.ant-input-lg {
  height: 48px;
  padding: 8px 8px 8px 12px;
}

.ant-btn-large {
  height: 48px;
  line-height: 42px;
}

.ant-btn-primary {
  background-color: black;
  border-color: black;
  color: white;
}

.ant-btn-primary:focus {
  background-color: black;
  border-color: black;
  color: white;
}

.ant-btn-primary:active {
  background-color: black;
  border-color: black;
  color: white;
}

.ant-btn-hover:active {
  background-color: black;
  border-color: black;
  color: white;
}

.ant-btn:active {
  background-color: black;
  border-color: black;
  color: white;
}

.ant-btn-link {
  font-weight: bold;
  color: #097090;
}

.App {
  background: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  /* &:hover {
      z-index: 1;
    } */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.h1 {
  font-size: x-large;
  color: red;
}


#pageTitle {
  font-size: 24px;
  text-align: center;
  width: 100%;
}

#legendTitle {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

#searchTitle {
  font-size: 14px;
  text-align: center;
  width: 100%;
}


#mapHeader {
  color: black;
  max-height: 140px;
}

#mapTitle {
  font-family: arial, sans-serif;
  background: #6ab446;
  color: black;
  width: 100%;
  padding: 8px;
  font-size: calc(10px + 2vmin);
  font-weight: 400;
  height: 7vh;
}

#mapFooter {
  font-family: Lato, sans-serif;
  background: black;
  color: white;
  height: 48px;
  font-size: 24px;
}

#termsButton {
  margin-top: 4px;
  color: green;
  font-weight: bold;
  font-size: large;
  text-align: left;
  width: 100%;
}

#copyright {
  color: white;
  width: 100%;
  font-weight: bold;
  font-size: large;
  text-align: left;
  margin-left: 16px;
  margin-top: 12px;
}

#legend {
  font-family: Arial, sans-serif;
  background: #2298a6;
  color: black;
  padding: 10px;
  margin: 10px;
  border: 3px solid #000;
  text-align: left;
  width: 33%;
  background-color: white;
}


#charitableNum {
  margin-top: 12px;
  color: white;
  text-align: right;
  font-size: large;
  font-weight: bold;
  margin-right: 16px;
}

@media (min-width:1024px) {

  #mapHeader {
    color: black;
    max-height: 140px;
    background-color: transparent;
  }
}

@media (min-width:1280px) {
  #pageTitle {
    font-size: 28px;
    margin: 0px 0px 8px 0px;
    text-align: center;
    font-weight: bold;
    width: 100%;
  }

  #legendTitle {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }

  #searchTitle {
    font-size: 24px;
    text-align: center;
    width: 100%;
  }

  #mapHeader {
    color: black;
    max-height: 140px;
  }

  #mapTitle {
    font-family: arial, sans-serif;
    background: #D2E8C7;
    color: black;
    width: 100%;
    padding: 8px;
    font-size: calc(10px + 2vmin);
    font-weight: 400;
    height: 7vh;
  }

  #mapFooter {
    font-family: Lato, sans-serif;
    background: black;
    color: white;
    height: 48px;
    align-content: center;
  }

  #termsButton {
    margin-left: 16px;
    margin: 4px;
    text-align: left;
    color: white;
    font-size: large;
  }

  #copyright {
    margin-top: 12px;
    margin-left: 16px;
    color: white;
    width: 100%;
    text-align: left;
    font-size: large;
  }


  #legend {
    font-family: Arial, sans-serif;
    background: #2298a6;
    color: black;
    padding: 10px;
    margin: 10px;
    border: 3px solid #000;
    text-align: left;
    width: 33%;
    background-color: white;
  }
}


.App-header {
  background-color: #097090;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #097090;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}